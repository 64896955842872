











































































































































































































@import '~vars';
@import '~mixins';

.information-bar {
  font-size: $-font-size-s;
  font-style: italic;
  color: $-font-color-secondary;
}

.white-bubble {
  padding: $-grid-gutter $-padding-unit;
  margin: $-grid-gutter 0;
  background-color: $-color-white;
  border-radius: $-border-radius;
  box-shadow: 0 0 18px 4px rgba(144, 164, 183, 0.22);
  z-index: 1;
}

.responses-table {
  margin: ($-grid-gutter * 2) 0;
  box-shadow: 0 0 18px 4px rgba(144, 164, 183, 0.22);

  @include res('xs') {
    font-size: $-font-size-s;
  }
}

.submit-answer {
  margin: $-grid-gutter 0;
}
